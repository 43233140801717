import { createAction, handleActions } from 'redux-actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import produce from 'immer';
import getConfig from 'next/config';

import fetcher from '~/api/lib/fetcher';
import { getV2AuthHeaders } from '~/utils/common';

import type { TLaundryPlanType, TUserUsingServiceInfo } from '~/types/subscription/common';

const { publicRuntimeConfig } = getConfig();
const { LAUNDRYGO_API_PREFIX } = publicRuntimeConfig;

const SET_USERINFO = 'SET_USERINFO';
const USER_USING_SERVICE_REQUEST = 'USER_USING_SERVICE_REQUEST';
const USER_USING_SERVICE_SUCCESS = 'USER_USING_SERVICE_SUCCESS';
const USER_USING_SERVICE_FAILURE = 'USER_USING_SERVICE_FAILURE';

export const setUserInfo = createAction<{ userId: number | string }>(SET_USERINFO);
export const userUsingServiceRequest = createAction(USER_USING_SERVICE_REQUEST);
export const userUsingServiceSuccess = createAction<TUserUsingServiceInfo>(
  USER_USING_SERVICE_SUCCESS
);
export const userUsingServiceFailure = createAction(USER_USING_SERVICE_FAILURE);

export type TUserState = {
  userId: number | string | null;
  laundryPlanType: TLaundryPlanType | null;
};

const initialState: TUserState = {
  userId: null,
  laundryPlanType: null,
};

export const reducer = handleActions(
  {
    [SET_USERINFO]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.userId = payload.userId;
      }),
    [USER_USING_SERVICE_SUCCESS]: (state, { payload }: { payload: any }) =>
      produce(state, (draft) => {
        const userUsingServiceInfo = payload as TUserUsingServiceInfo;
        const { subscriptionLaundryPlan } = userUsingServiceInfo;

        draft.laundryPlanType = subscriptionLaundryPlan.laundryPlanType;
      }),
    [USER_USING_SERVICE_FAILURE]: (state) =>
      produce(state, (draft) => {
        draft.laundryPlanType = null;
      }),
    [USER_USING_SERVICE_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.laundryPlanType = null;
      }),
  },
  initialState
);

function* watchUserUsingServiceSaga() {
  const { headers } = yield select((state) => state.http);

  try {
    const { data } = yield call(
      fetcher.get,
      `${LAUNDRYGO_API_PREFIX}/purchase/v1/subscription/using-service`,
      {
        headers: {
          ...getV2AuthHeaders(headers),
        },
      }
    );

    yield put(userUsingServiceSuccess(data.data));
  } catch (error) {
    yield put(userUsingServiceFailure());
  }
}

export { watchUserUsingServiceSaga };

export const sagas = [takeLatest(USER_USING_SERVICE_REQUEST, watchUserUsingServiceSaga)];

const userInfoSelector = (state) => state.user.userInfo;

export const selectUserInfo = createSelector(userInfoSelector, (userInfo) => {
  return userInfo;
});
